/**
 * @generated SignedSource<<790d03c304413489d134c635134c24ec>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AIGeneratedTextOrigin = "GENERATED_TEXT_ORIGIN_AUTOMATED_JOURNEYS" | "GENERATED_TEXT_ORIGIN_AUTOMATED_JOURNEYS_CHAIN" | "GENERATED_TEXT_ORIGIN_AUTOMATED_WELCOME_JOURNEY" | "GENERATED_TEXT_ORIGIN_BRAND_VOICE_SUMMARY" | "GENERATED_TEXT_ORIGIN_CAMPAIGN_SUGGESTIONS_HOMEPAGE" | "GENERATED_TEXT_ORIGIN_COPY_ASSISTANT_EMAIL_SUBJECT_LINE" | "GENERATED_TEXT_ORIGIN_COPY_ASSISTANT_SMS" | "GENERATED_TEXT_ORIGIN_UNKNOWN" | "%future added value";
export type OpenAIModel = "OPENAI_MODEL_GPT_3_5_TURBO" | "OPENAI_MODEL_GPT_4" | "OPENAI_MODEL_GPT_4_300_TEST" | "OPENAI_MODEL_GPT_4_O" | "OPENAI_MODEL_GPT_4_TURBO" | "OPENAI_MODEL_UNKNOWN" | "OPEN_AI_MODEL_GPT_4_0_MINI" | "%future added value";
export type PromptRefinementOption = "PROMPT_REFINEMENT_OPTION_ADD_EMOJIS" | "PROMPT_REFINEMENT_OPTION_ADD_URGENCY" | "PROMPT_REFINEMENT_OPTION_MAKE_CASUAL" | "PROMPT_REFINEMENT_OPTION_MAKE_FUNNY" | "PROMPT_REFINEMENT_OPTION_MAKE_SHORTER" | "PROMPT_REFINEMENT_OPTION_UNKNOWN" | "PROMPT_REFINEMENT_OPTION_USE_BRAND_VOICE" | "%future added value";
export type GenerateAITextInput = {
  companyId: string;
  n?: number | null;
  origin: AIGeneratedTextOrigin;
  prompt?: ReadonlyArray<string> | null;
  promptRefinementOptions?: ReadonlyArray<PromptRefinementOption> | null;
  template?: GenerativeAITextTemplateInput | null;
  useMessageEvaluation?: boolean | null;
  vendorOptions?: TextGenVendorOptions | null;
};
export type GenerativeAITextTemplateInput = {
  templateName: string;
  version: string;
};
export type TextGenVendorOptions = {
  vendorOptions?: TextGenVendorOptionsVendorOptions | null;
};
export type TextGenVendorOptionsVendorOptions = {
  openaiOptions?: OpenAIOptions | null;
};
export type OpenAIOptions = {
  model: OpenAIModel;
};
export type useGenerateTextsFromTemplateGenerateAITextMutation$variables = {
  input: GenerateAITextInput;
};
export type useGenerateTextsFromTemplateGenerateAITextMutation$data = {
  readonly generateAIText: {
    readonly generatedTextResponses: ReadonlyArray<{
      readonly aiGeneratedTextMetadata: ReadonlyArray<{
        readonly key: string;
        readonly value: string;
      }>;
      readonly groupId: string;
      readonly id: string;
      readonly source: string;
      readonly text: string;
    }>;
  } | null;
};
export type useGenerateTextsFromTemplateGenerateAITextMutation$rawResponse = {
  readonly generateAIText: {
    readonly generatedTextResponses: ReadonlyArray<{
      readonly aiGeneratedTextMetadata: ReadonlyArray<{
        readonly key: string;
        readonly value: string;
      }>;
      readonly groupId: string;
      readonly id: string;
      readonly source: string;
      readonly text: string;
    }>;
  } | null;
};
export type useGenerateTextsFromTemplateGenerateAITextMutation = {
  rawResponse: useGenerateTextsFromTemplateGenerateAITextMutation$rawResponse;
  response: useGenerateTextsFromTemplateGenerateAITextMutation$data;
  variables: useGenerateTextsFromTemplateGenerateAITextMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "GenerateAITextPayload",
    "kind": "LinkedField",
    "name": "generateAIText",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AIGeneratedText",
        "kind": "LinkedField",
        "name": "generatedTextResponses",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "groupId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "text",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "source",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AiGeneratedTextMetadataEntry",
            "kind": "LinkedField",
            "name": "aiGeneratedTextMetadata",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "key",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useGenerateTextsFromTemplateGenerateAITextMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useGenerateTextsFromTemplateGenerateAITextMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e88881ed5af13ffb392fe756fd204c5a",
    "id": null,
    "metadata": {},
    "name": "useGenerateTextsFromTemplateGenerateAITextMutation",
    "operationKind": "mutation",
    "text": "mutation useGenerateTextsFromTemplateGenerateAITextMutation(\n  $input: GenerateAITextInput!\n) {\n  generateAIText(input: $input) {\n    generatedTextResponses {\n      id\n      groupId\n      text\n      source\n      aiGeneratedTextMetadata {\n        key\n        value\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "57050450e625340f63ac771fc5897188";

export default node;
